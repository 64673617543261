.countdown {
  text-align: center;
  height: 100vh;
  width: 100vw;
  background-image: url('./greenBackground.png');
}
.button {
  position: absolute;
  right: 10%;
  top: 30%;
  margin: 10px;
  margin: 0px;
  background-color: transparent;
  border-color: transparent;
}
@media screen and (max-width: 900px) {
  .button {
    top: 80%;
  }
}
.down {
  color: white;
  font-size: 150px;
  margin: 0px;
  text-align: left;
  font-weight: bold;
}
@media screen and (max-width: 900px) {
  .down {
    font-size: 80px;
  }
}
@media screen and (max-width: 700px) {
  .down {
    font-size: 60px;
  }
}
.count {
  margin: 0px;
  margin-bottom: -80px;
}
@media screen and (max-width: 900px) {
  .count {
    margin-bottom: -30px;
  }
}
.timer {
  text-align: center;
  height: 100vh;
  width: 100vw;
  background-image: url('./greenBackground.png');
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media screen and (max-width: 350px) {
  .timer {
    padding-top: 20px;
    padding-bottom: 20px;
    flex-flow: wrap;
    height: 100%;
    width: 100%;
  }
}
.timer-results {
  text-align: center;
  height: 100vh;
  width: 100vw;
  background-image: url('./greenBackground.png');
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.timer-instructions {
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  font-size: 60px;
  font-weight: bold;
  text-align: left;
  letter-spacing: 10px;
  margin-top: 0px;
}
@media screen and (max-width: 800px) {
  .timer-instructions {
    position: relative;
    font-size: 35px;
  }
}
@media screen and (max-width: 800px) {
  .instructions {
    position: relative;
  }
}
.timer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
@media screen and (max-width: 800px) {
  .timer-container {
    margin: 20px;
    flex-wrap: wrap;
  }
}
.month {
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  color: white;
  font-weight: bold;
  font-size: 20px;
}
.input {
  margin: 10px;
  height: 50px;
  width: 50px;
  font-size: 24px;
  border-color: white;
  border-width: 5px;
  background-color: transparent;
  border-style: double;
  text-align: center;
  color: white;
  font-weight: bold;
}
.day {
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  color: white;
  font-weight: bold;
  font-size: 20px;
}
.year {
  width: 100px;
  margin-left: 15px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  color: white;
  font-weight: bold;
  font-size: 20px;
}
.time {
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  color: white;
  font-weight: bold;
  font-size: 20px;
  margin-left: 20px;
  width: 100px;
}
.submit {
  background-color: transparent;
  color: white;
  border-color: white;
  border-width: 4px;
  font-size: 15px;
  font-weight: bold;
  margin-top: 30px;
}
.margin-fifty {
  margin-left: 0px;
}
@media screen and (min-width: 700px) {
  .margin-fifty {
    margin-left: 50px;
  }
}
.margin-hundred {
  margin-left: 0px;
}
@media screen and (min-width: 700px) {
  .margin-hundred {
    margin-left: 100px;
  }
}
.margin-one-fifty {
  margin-left: 0px;
}
@media screen and (min-width: 700px) {
  .margin-one-fifty {
    margin-left: 150px;
  }
}
.margin-two-hundred {
  margin-left: 0px;
}
@media screen and (min-width: 700px) {
  .margin-two-hundred {
    margin-left: 200px;
  }
}
.margin-two-fifty {
  margin-left: 0px;
}
@media screen and (min-width: 700px) {
  .margin-two-fifty {
    margin-left: 250px;
  }
}
.margin-three-hundered {
  margin-left: 0px;
}
@media screen and (min-width: 700px) {
  .margin-three-hundered {
    margin-left: 300px;
  }
}
